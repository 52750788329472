import React, { Fragment, useEffect, useRef } from "react";
import { withFormsy } from "formsy-react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import "../../style/bootstrap-components/text-field.scss";

const TextField = (props) => {
  const inputRef = useRef();
  const { required, hasError, errorText, outlined, hideRequiredMark, label, suffix, errorMessage } = props;
  let errText = errorText || errorMessage;
  let hasErr = hasError ||  (errText || errorMessage) ? true : false;


  useEffect(() => {
    if (props.setValue) {
      props.setValue(props.defaultValue)
    }
  }, [props.defaultValue])

  const field = (
    <Fragment>
      <input
        ref={inputRef}
        name={props.name}
        type={props.type}
        required={props.required}
        placeholder={props.placeholder || ""}
        className={`${props.inputClass} ${(hasErr) && 'has-error'}`}
        onChange={(e) => {
          props.setValue(e.target.value);
          props.onChange && props.onChange(e);
        }}
        value={props.value || ""}
        disabled={props.disabled}
      />
    </Fragment>
  );

  const ErrorMessage = () => {
    if (!errText && !errorMessage) {
      return null;
    }

    return <div className="custom-text-field-error">{errText ? errText : "Oops! There's an error"}</div>
  }

  if (props.inline) {
    return (
      <Row className="align-items-center inline custom-text-field">
        <label className="col-3">{label}{required && !hideRequiredMark &&  <span className="text-danger">{" *"}</span>}</label>
        <Col>
          {field}
          {props.afterField}
          {hasErr && <ErrorMessage errText={errText}/>}
        </Col>
      </Row>
    );
  }
  
  if (props.outlined) {
    return (
      <div className="custom-text-field outlined">
        {field}
        <label className={`${props.value && 'has-value'}`}>
          {label}{required && !hideRequiredMark &&  <span className="text-danger">{" *"}</span>}
        </label>
        {suffix && <div className="outlined-suffix">{suffix}</div>}
        {hasErr && <ErrorMessage errText={errText}/>}
      </div>
    );
  }

  return (
    <div className={`custom-text-field ${suffix && "has-suffix"}`}>
      <label className="text-field-base-label">{label}{required && !hideRequiredMark && <span className="text-danger">{" *"}</span>}</label>
      {field}
      {suffix && <div className="basic-suffix">{suffix}</div>}
      {hasErr && <ErrorMessage errText={errText}/>}
    </div>
  );
}

TextField.propTypes = {
  hasErr: PropTypes.bool, // states whether the input has error;
  errText: PropTypes.string, // can be used to add error text below the input
  required: PropTypes.bool, // can be used to have isRequired mark (*) beside the label
  hideRequiredMark: PropTypes.bool, // states whether to show required mark or not
  outlined: PropTypes.bool, // outlined variant of the text-field
  inline: PropTypes.bool, // inline variant of the text-field
  suffix: PropTypes.node, // a component attached on end of the input i.e an icon
}

export default withFormsy(TextField);
