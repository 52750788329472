import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

import "../../style/bootstrap-components/button.scss";

const CustomButton = (props) => {
    const { className, type, disabled, onClick, size, boxed, fullWidth, ...rest} = props;

    return (
        <Button
            className={`custom-button ${className} ${size === "lg" && "size-large"} ${boxed && "boxed-type"} ${fullWidth && "w-100"}`}
            type={type}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {props.children}
        </Button>
    )
}

CustomButton.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    boxed: PropTypes.bool, // Sets button to rectangular
    variant: PropTypes.string, // Variants include ["primary", "secondary", "success", "danger", "info", "light", "dark", "link", "no-border")
    fullWidth: PropTypes.bool,
}

export default CustomButton;
